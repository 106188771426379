import React from 'react'
import "./Navbar4.css";
export const Navbar4 = () => {
    return (
        <div className="navbar4-body">
            <h5> FREE SHIPPING & RETURNS !</h5>
        </div>
    )
}

