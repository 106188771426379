import React from 'react'
import "./Home3.css";
const Home3 = () => {
    return (
        <div className="home3-body">

              
            <img src="https://firebasestorage.googleapis.com/v0/b/loftydreams-307f2.appspot.com/o/React-Images%2FWE%20ARE%20THE%20TRUSTED%20SELLER%20AT%20(1).gif?alt=media&token=0dd562e1-b1db-4880-bcb5-34dc5e55a198" alt="seller" />
            {/* <div className="home3-heading">
                <h1> WE ARE THE TRUSTED SELLER AT</h1>
            </div>
            <div className="platform">
             <img src="" alt="" className=""/>
             <img src="" alt="" className=""/>
             <img src="" alt="" className=""/>
             <img src="" alt="" className=""/>
            </div> */}

        </div>
    )
}

export default Home3
