export const chatQuestions = [
  {
    id: 1,
    ques: "What is recliner sofa?",
  },
  {
    id: 2,
    ques: "Are recliner chairs bad for your back?",
  },
];
